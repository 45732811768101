<template>
  <div>
    <starter-navbar :colorOnScroll="400"></starter-navbar>
    <b-container>
      <section class="topSection">
        <header style="margin-top:79px"><h3>{{ $t('tutteLeMaglie') }} <n-button type="primary" @click="goToMap">{{ $t('vediSuMappa') }}</n-button></h3></header>
        <p>{{$t('intro')}}</p>
        <collapse ref="collapseClusters">
          <collapse-item v-for="cluster in clusters" :title='s(cluster,["tri:Titolo_"+$store.getters.getLanguage,0,"@value"])'
                         :key="cluster['o:id']" :initiallyActive="true">
            <CardList
                :list-title="'Tutte le maglie del cluster ' + cluster['o:id'] "
                v-if="maglie[cluster['o:id']]"
                :elems="maglie[cluster['o:id']]"
                :handler="selectMaglia"
                :thumbnail="thumbnailSrc"
                :alt="alt"
                :title="cardTitle"
                :body="cardBody"
            />
          </collapse-item>
          <collapse-item :title="$t('altreStorie')" key="altreStorie_nocluster" :initially-active="true" v-if="maglieOrfane.length > 0">
            <CardList
                list-title="Tutte le maglie orfane"
                :elems="maglieOrfane"
                :handler="selectMaglia"
                :thumbnail="thumbnailSrc"
                :alt="alt"
                :title="cardTitle"
                :body="cardBody"
            />
          </collapse-item>
        </collapse>
      </section>
    </b-container>
  </div>
</template>
<script>
import VueRouter from 'vue-router'

const {isNavigationFailure, NavigationFailureType} = VueRouter
import {Collapse, CollapseItem} from '@/components'
import CardList from './components/CardList.vue'
import {Button} from '@/components'
import StarterNavbar from '../layout/StarterNavbar.vue';
import Vue from 'vue';
import NButton from "@/components/Button";

const Common = require('@/Common.vue').default

export default {
  name: 'allMaglie',
  components: {NButton, [Button.name]: Button, Collapse, CollapseItem, StarterNavbar, CardList},

  data: function () {
    return {
      clusters: [],
      maglie: {}, // Cluster id => Lista di maglie
      thumbnails: {}, // Item id => media object
      redirectionUrl: {}, // Maglia id => item id
      maglieOrfane: [] // Maglie che non appartengono ad alcun cluster
    }
  },
  mounted: function () {
    const self = this

    // Prendiamo tutti i clusters
    Common.getElemByClass(this, 23, response => {
      self.clusters = response.body
      self.clusters.forEach(cl => {
        // Prendiamo tutte le rispettive maglie
        Common.getElemByPropertyId(self, 33, cl['o:id'], response2 => {
          var maglie = []
          response2.body.forEach(m => {
            if (self.s(m, ['dcterms:accessRights', 0, '@value']) === "YES" || localStorage.getItem("admin") === self.$store.getters.getPw) {
              maglie.push(m)
            }
          })
          self.initializeMaglia(response2, self, () => Vue.set(self.maglie, cl['o:id'], maglie));
        })
      })
    })

    // Prendiamo tutte le maglie senza cluster
    Common.getElementWithoutValueForResource(this, 33, response => {
      self.initializeMaglia(response, self, () => {
        response.body.forEach(maglia => {
          if (self.s(maglia, ['dcterms:accessRights', 0, '@value']) === "YES" || localStorage.getItem("admin") === self.$store.getters.getPw) {
            self.maglieOrfane.push(maglia)
          }
        })
        //self.maglieOrfane = response.body
      })
    }, 124)
  },
  methods: {
    initializeMaglia: function (httpResponse, self, callback) {
      var toBeLoaded = httpResponse.body.length
      httpResponse.body.forEach(maglia => {
            if (self.s(maglia, ['dcterms:accessRights', 0, '@value']) === "YES" || localStorage.getItem("admin") === self.$store.getters.getPw) {
              // Prendiamo la thumbnail di ogni maglia
              Common.getElementImages(this, maglia, mediaList => {
                self.thumbnails[maglia["o:id"]] = Common.findThumbnail(mediaList)
                // Prendiamo anche i luoghi connessi (in modo da fornire un url valido al click sulla card di una maglia)
                Common.getElemByPropertyId(self, 258, maglia["o:id"], response3 => {
                  self.redirectionUrl[maglia["o:id"]] = response3.body[0]["o:id"] // Ci serve, in realtà, solo il primo elemento.
                  toBeLoaded--
                  if (toBeLoaded === 0) {
                    callback() // Solo quando abbiamo inizializzato tutto quello che ci serve della maglia facciamo comparire la CardList in questo modo
                  }
                })
              })
            }
            else{
              toBeLoaded--
              if (toBeLoaded === 0) {
                callback() // Solo quando abbiamo inizializzato tutto quello che ci serve della maglia facciamo comparire la CardList in questo modo
              }
            }
      })
    },
    // Wrapper for Common.safeString
    s(unsafeString, chain, debugging_label = null) {
      return Common.safeString(unsafeString, chain, debugging_label)
    },
    selectMaglia: function (elem) {
      this.$router.push({path: '/maglia/' + this.redirectionUrl[elem["o:id"]]})
    },
    goToMap: function(){
      this.$router.push({path: '/map'})
    },
    thumbnailSrc: function (elem) {
      return Common.safeString(this.thumbnails, [elem["o:id"], 'thumbnail_display_urls', 'square']);
    },
    alt: function (elem) {
      return this.$t('imageFor') + Common.safeString(elem, ["tri:Titolo_" + this.$store.getters.getLanguage, 0, '@value'])
    },
    cardTitle: function (elem) {
      return Common.safeString(elem, ["tri:Titolo_" + this.$store.getters.getLanguage, 0, '@value'])
    },
    cardBody: function (elem) {
      return Common.safeString(elem, ["tri:Descrizione_" + this.$store.getters.getLanguage, 0, "@value"]).substr(0, 200) + (Common.safeString(elem, ["tri:Descrizione_" + this.$store.getters.getLanguage, 0, "@value"]).length > 200 ? '...' : '')
    }
  },
};
</script>
<style scoped>
h3, .centered {
  text-align: center;
}
</style>