<template>
  <div>
    <header style="margin-top:79px"><h3>{{$t('tuttiIPercorsi')}} <n-button type="primary" @click="goToStories">{{ $t('vediLista') }}</n-button></h3></header>
    <b-alert
        v-if="isDateAfterToday(new Date('2022-09-30'))"
        :show="dismissCountDown"
        dismissible
        fade
        style="background-color:white !important; margin-left: 2em; margin-right:2em; border: 1px solid black; color:black; text-align:center;"
        @dismiss-count-down="countDownChanged"
    >
      ******************************<br>
      <br>
      UNIGHT - Notte Europea delle Ricercatrici e dei Ricercatori 2022<br>
      "Triangolazioni" sarà a Palazzo Badini, via Verdi 10<br>
      venerdì 30/9 dalle 17 alle 23.<br>
      Vienici a trovare!<br>
      <br>
      ******************************
    </b-alert>

    <Header
        :showMap="true"
        :showListButton="showList"
        ref="header"
        map-height="90vh"
        :margin0="true"
    />
    <section id="list" class="forScreenReaders" v-if="this.pivotVicini.length > 0">
      <b-container fluid>
        <CardList
            list-title="Pivot vicini"
            :elems="this.pivotVicini"
            :handler="this.selectPivot"
            :thumbnail="this.thumbnailSrc"
            :alt="this.alt"
            :title="this.cardTitle"
            :body="this.cardBody"
            v-if="!this.loading"
        />
        <b-container fluid id="listbutton" class="centered">
          <n-button @click="showList"><i class="now-ui-icons location_compass-05"></i></n-button>
        </b-container>
      </b-container>
    </section>
    <alert id="information" type="danger" v-if="positionError !== null">{{positionError}}</alert>
    <section>
      <b-container v-if="!this.loading && this.pivotVicini.length === 0">
        <b-container fluid>
          <b-card class="overflow-hidden" style="margin-top:30px; background-color:#ffe9ba">
            <b-card-body>
              <b-card-text class="centeredText">
                {{$t('nessunEl')}}
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-container>
      </b-container>
    </section>
  </div>
</template>
<script>
import VueRouter from 'vue-router'
const { isNavigationFailure, NavigationFailureType } = VueRouter
import {Alert} from '@/components'
import CardList from './components/CardList.vue'
import Header from './components/Header.vue'
import {Button} from '@/components'

const Common = require('@/Common.vue').default
export default {
  name: 'starter',
  components: {Alert,CardList,Header,[Button.name]: Button},
  data: function(){
    return {
      pivotVicini : [], // Tutti i pivot vicini
      loading: true,
      positionError: null, // Messaggio di errore in caso di problemi con la geolocalizzazione,
      thumbnails: {}, // Pivot id => media object,
      orangeIcon: new L.Icon({
        iconUrl: 'https://exptriangolazioni.ontomap.eu/out2.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [27,34],
        iconAnchor: [13,34.5],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      }),
      blackIcon: new L.Icon({
        iconUrl: 'https://exptriangolazioni.ontomap.eu/output-onlinepngtools_others.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [27,34],
        iconAnchor: [13,34.5],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      }),
      dismissSecs: 25,
      dismissCountDown: 0,
    }
  },
  mounted : function(){
    // Get user's position
    this.getPosition()
    this.showAlert()
    // Tri-LOG
    Common.addLog(this,this.$route.path, res => {});
  },
  methods: {
    goToStories: function(){
      this.$router.push({path: '/'})
    },
    isDateAfterToday(date) {
      return new Date(date.toDateString()) >= new Date(new Date().toDateString());
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    showList: function(){
      var yourUl = document.getElementById("mapContainer");
      yourUl.style.display = yourUl.style.display === 'none' ? '' : 'none';
      document.getElementById("list").classList.toggle("forScreenReaders")
    },
    getPosition: function(){
      /*if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.updatePosition,this.showError);
      } else {
        this.positionError = this.$t('geolocNonSupportata');
      }*/
    },
    updatePosition: function(position,err = false){
      if(err){
        this.$store.commit('lat',0)
        this.$store.commit('lng',0)
      }
      else {
        this.$store.commit('lat', position.coords.latitude)
        this.$store.commit('lng', position.coords.longitude)
      }
      var self = this;
      Common.getElemByPropertyId(this,'','&per_page=10000', (response)  => {
        var elementi = response.body
        var self = this
        self.initElems(elementi,position)
      },122, response => {self.positionError = "Abbiamo riscontrato un problema... Riprova più tardi";})
    },
    endProcessing: function (nuoviRisultati, self) {
      var risultatiObj = {}
      nuoviRisultati.forEach(elem => {
            if (!risultatiObj.hasOwnProperty(elem["o:id"])) {
              self.pivotVicini.push(elem)
              risultatiObj[elem["o:id"]] = true
            }
          }
      )
      var waiting = nuoviRisultati.length;
      nuoviRisultati.forEach(elem => {
        Common.getElementImages(this, elem, mediaList => {
          self.thumbnails[elem["o:id"]] = Common.findThumbnail(mediaList)
          waiting--
          if (waiting === 0) {
            self.loading = false
          }
        });
      })
      self.map.initMap(45.0753224, 7.64997)
      this.pivotVicini.forEach(el => {
        this.map.addToMap(el["lat"], el["lng"], Common.safeString(el, ["tri:Titolo_" + this.$store.getters.getLanguage, 0, "@value"]), el)
        //el.marker.on('click', () => self.selectPivot(el))
      })
    },
    changeItem: function(el){
      this.$router.push({"path":"/maglie-pivot/"+el["o:id"]})
    },
    initElems: function(elementi, position){
      var nuoviRisultati = [];
      var self = this
      var waitingFor = elementi.length
      var doneObj = {}
      elementi.forEach(pivot => { // Pivot
        if(pivot.hasOwnProperty("o-module-mapping:marker")){
          Common.getElemByPropertyId(this,259,pivot["o:id"], function(response){ // Geodati
                var waitingForGeodati = response.body.length
                response.body.forEach(el => {
                    if(!doneObj.hasOwnProperty(pivot["o:id"])) {
                      Common.getElemByUrl(self, self.s(el, ["tri:appartiene_a_maglia", 0, "@id"]), res => { // Maglia corrispondente al geodato
                        if (self.s(res.body, ['dcterms:accessRights', 0, '@value']) === "YES" || localStorage.getItem("admin") === self.$store.getters.getPw) {
                          Common.getElemByUrl(self, pivot["o-module-mapping:marker"][pivot["o-module-mapping:marker"].length - 1]["@id"], response => {
                            pivot["lat"] = response.body["o-module-mapping:lat"]
                            pivot["lng"] = response.body["o-module-mapping:lng"]
                            waitingForGeodati--
                            if (!doneObj.hasOwnProperty(pivot["o:id"])) {
                              nuoviRisultati.push(pivot)
                              doneObj[pivot["o:id"]] = true
                              waitingFor--
                              if (waitingFor === 0) {
                                self.endProcessing(nuoviRisultati, self);
                              }
                            }
                          })
                        }
                        else{ // Questa maglia (e di conseguenza il geodato) non è accessibile con i permessi attuali.
                          waitingForGeodati--
                          if(waitingForGeodati === 0 && !doneObj.hasOwnProperty(pivot["o:id"])){ // Se abbiamo guardato tutti i geodati, e non abbiamo ancora aggiunto il pivot, significa che non dobbiamo mostrarlo. Decrementiamo il counter comunque
                            waitingFor--
                            if (waitingFor === 0) {
                              self.endProcessing(nuoviRisultati, self);
                            }
                          }
                        }
                      })
                    }
                })
          })

        }
        else{
          waitingFor--
          if(waitingFor === 0){
            this.endProcessing(nuoviRisultati, self);
          }
        }
      })
    },
    showError: function(error) {
      var position = {
        'coords' : {
          'latitude': 45.07096535,
          'longitude': 7.68580460488738
        }
      }
      this.updatePosition(position,true)
      switch(error.code) {
        case error.PERMISSION_DENIED:
          this.positionError = this.$t('permissionDenied')
          break;
        case error.POSITION_UNAVAILABLE:
          this.positionError = this.$t('geoFailed')
          break;
        case error.TIMEOUT:
          this.positionError = this.$t('timeoutGeo')
          break;
        case error.UNKNOWN_ERROR:
          this.positionError = this.$t('unknownGeo')
          break;
      }
    },
    selectPivot: function(elem){
      this.$router.push({path: '/maglie-pivot/'+elem["o:id"]})
    },
    s: function(e,a,d=null){
      return Common.safeString(e,a,d)
    },
    thumbnailSrc: function(elem){return Common.safeString(this.thumbnails,[elem["o:id"],'thumbnail_display_urls','square']);},
    alt: function(elem){return this.$t('imageFor') + Common.safeString(elem,["tri:Titolo_"+this.$store.getters.getLanguage,0,'@value'])},
    cardTitle: function(elem){return Common.safeString(elem,["tri:Titolo_"+this.$store.getters.getLanguage,0,'@value'])},
    cardBody: function(elem){return Common.safeString(elem,["dcterms:description",0,"@value"]).substr(0,200) + (Common.safeString(elem,["dcterms:description",0,"@value"]).length > 200 ? '...' : '')}
  },
  computed: {
    map: function(){
      return this.$refs.header.$refs.mapPercorso
    },
  },
  watch: {
    $route (to, from){
      document.getElementById("mapContainer").style.display = ''
      if(document.getElementById("list")){
        document.getElementById("list").classList.add("forScreenReaders")
      }
    }
  }
};
</script>
<style scoped lang="scss">
.b-card{
  background-color:#fff7e6
}
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@include media-breakpoint-up(md) {
  .cardTextContent{
    height:200px;
    width:200px;
  }
  .b-card{
    margin-left:5%;
    max-width:90%;
  }
}
h3, .centered{
  text-align: center;
}
</style>
<style>
.alert .close{
  color: black !important;
}
</style>