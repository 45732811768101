<template>
    <div>
        <starter-navbar :colorOnScroll="400" ref="navb"></starter-navbar>
        <b-container id="cont">
            <header>
                <h3>
                    <b-row>
                        <b-col md="11">
                            {{$t("settings")}}
                        </b-col>
                        <b-col md="1" style="text-align:center;">
                            <b-icon-x id="closeIcon" :alt="$t('back')" @click="back()"></b-icon-x>
                        </b-col>
                    </b-row>
                </h3>
            </header>
            <section>
                <!--<b-row class="my-1">
                    <b-col sm="3">
                        <label for="radius_val"><p>{{$t("radius")}}</p></label>
                    </b-col>
                    <b-col sm="9">
                        <b-form-input 
                            type="number"
                            min="0" 
                            step="0.1"
                            value="1"
                            v-model="radius_val"
                            name="radius_val"
                            id="radius_input"
                            style="height:5em;"></b-form-input>
                    </b-col>
                </b-row>-->
                <b-row>
                    <b-col sm="3">
                        <label for="language_sel"><p>{{$t("lingua")}}:</p></label>
                    </b-col>
                    <b-col sm="9">
                        <b-form-select v-model="curr_lang" :options="options" size="sm" id="language_sel"></b-form-select>
                    </b-col>
                </b-row>
                <b-row>
                    <h3><n-button type="primary" style="padding:0.25em; margin-left: 15px" @click="openAuth()" v-if="(this.$store.getters.getPw !== admin && this.auth_now != 1) || this.auth_now == -1">{{$t('adminRights')}}</n-button></h3>
                    <h3><n-button type="primary" style="padding:0.25em; margin-left: 15px" @click="removeAuth()" v-if="(this.$store.getters.getPw === admin && this.auth_now != -1) || this.auth_now == 1">{{$t('userRights')}}</n-button></h3>
                </b-row>
            </section>
        </b-container>
        <starter-footer :backgroundColor="'black'"></starter-footer>
    </div>
</template>
<script>
import StarterNavbar from '../layout/StarterNavbar.vue';
import StarterFooter from '../layout/StarterFooter.vue';
import {Button} from '@/components';
import Swal from 'sweetalert2';

export default {
    components: {StarterNavbar,StarterFooter, [Button.name]: Button},
    data(){
        return{
            auth_now: 0,
            options: [
                { value: 'it',text:'Italiano'},
                { value: 'en',text:'English'},
            ],
            curr_lang : undefined
        }
    },
    // Fabio Language
    mounted() {
        this.curr_lang = this.$store.getters.getLanguageSys;
    },
    // Fabio Language
    watch: {
        curr_lang(new_lan, old_lan) {
            this.$i18n.locale = new_lan
            this.$store.commit('updateLanguageSys', new_lan) 
        }
    },
    computed: {
        radius_val: {
            get () {
                return this.$store.getters.getRadiusVal
            },
            set (value) {
                this.$store.commit('updateRadius', value)
            }
        },
        // ANGELO Language
        // language: {
        //     get () {
        //         for(let i = 0; i < this.options.length; i++){            
        //             if(this.options[i].value === this.$store.getters.getLanguage){ 
        //                 return this.options[i]
        //             }
        //         }
        //         console.error("No language specified")
        //         return null
        //     },
        //     set (value) {
        //         console.log('update language')
        //         // this.$store.commit('updateLanguage', value)
        //         this.$store.commit('updateLanguage', value)                
        //     }
        // },
        admin: {
            get () {
                return localStorage.getItem("admin")
            },
            set (value) {
                localStorage.setItem("admin",value)
                this.auth_now = 1
            }
        }
    },
    methods: {
        back(){
            if(this.$parent.hasOwnProperty("back"))
                this.$parent.back();
            else
                this.$router.go(-1);
        },
        openAuth(){
            Swal.fire({
                title: this.$t("pwPrompt"),
                html: `<input type="password" id="password" class="swal2-input" placeholder="Password">`,
                confirmButtonText: this.$t("conferma"),
                focusConfirm: false,
                preConfirm: () => {
                    const password = Swal.getPopup().querySelector('#password').value
                    if (!password) {
                        Swal.showValidationMessage(this.$t("pwPrompt"))
                    }
                    else if(this.$store.getters.getPw !== password){
                        Swal.showValidationMessage(this.$t("wrongPw"))
                    }
                    return {password: password }
                }
                }).then((result) => {
                    this.admin = result.value.password 
                    this.$refs.navb.admin = this.admin                 
                })
        },
        removeAuth(){
            localStorage.removeItem("admin")
            this.auth_now = -1
            this.$refs.navb.admin = null
        }
    }
}
</script>
<style scoped>
#cont{
    margin-top:100px;
    margin-bottom:30px
}
h3, h3{
    text-align:center;
}
#closeIcon{
    opacity: 0.5;
}

#closeIcon:hover{
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    opacity: 1;
}
.button-auth-setting {
    padding-left: 15px;
    /* margin-right: 15px; */
}
</style>
<style>
.swal2-confirm{
    color:black !important;
    background-color: #FFAB4C !important;
}
</style>