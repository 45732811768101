<template>
  <div>
    <starter-navbar :colorOnScroll="400" ref="navb"></starter-navbar>
    <b-container id="cont">
      <header>
        <h3>
          <b-row>
            <b-col md="11">
              Esperimento - fase {{getPhase()}}
            </b-col>
          </b-row>
        </h3>
      </header>
      <section>
        <p v-if="getPhase() > 0">Il tuo identificativo anonimo: {{getUuid()}}</p>
        <p v-if="getPhase() > 0 && getPhase() <= 3">
          Ti chiediamo di esplorare il sistema che troverai qui: <a :href="getLink()" target="_blank" @click="() => this.showContinueButton = true">clicca qui</a>.<br>
          Dopo aver letto l'introduzione alla storia, esplora 1-2 luoghi legati ad essa.<br>
          Non chiudere questa scheda. Quando hai finito di esplorare, clicca sul bottone seguente:<br>
          <n-button type="primary" @click="postTaskForm">Continuiamo</n-button>
        </p>
        <p v-else-if="getPhase() === 5">
          Grazie, l'esperimento è terminato!
        </p>
      </section>
    </b-container>
    <starter-footer :backgroundColor="'black'"></starter-footer>
  </div>
</template>

<script>
import StarterNavbar from '../layout/StarterNavbar.vue';
import StarterFooter from '../layout/StarterFooter.vue';
import {Button} from '@/components';
import NButton from "@/components/Button";

export default {
  components: {NButton, StarterNavbar, StarterFooter, [Button.name]: Button},
  methods: {
    getUuid: function() {
      return localStorage.getItem("uuid") ? localStorage.getItem("uuid") : 'ERRORE: per favore segnala il problema.'
    },
    getPhase: function(){
      return localStorage.getItem("phase") && parseInt(localStorage.getItem("phase"))  <= 5 ? parseInt(localStorage.getItem("phase")) : 0
    },
    addToPhase: function(){
      localStorage.setItem("phase", this.getPhase() + 1)
    },
    getLink: function(){
      return '/maglia/' + JSON.parse(localStorage.getItem("stories"))[this.getPhase() - 1] + '?sys=' + JSON.parse(localStorage.getItem("systems"))[this.getPhase() - 1]
    },
    postTaskForm: function(){
      if(!this.showContinueButton && !this.alreadyClicked){
        alert("Prima di continuare, devi esplorare il sistema cliccando sul link qui sopra.")
      }
      else if(!this.alreadyClicked){
        this.alreadyClicked = true
        this.showContinueButton = false
        const oldVal = this.getPhase() - 1
        this.addToPhase()
        location.href = JSON.parse(localStorage.getItem("forms"))[JSON.parse(localStorage.getItem("systems"))[oldVal]] + this.getUuid()
      }
    }
  },
  data () {
    return {
      showContinueButton: false,
      alreadyClicked: false
    }
  },
  mounted() {
    if(this.getPhase() === 0){
      let date = new Date();
      let day = String(date.getDate()).padStart(2, '0');
      let hours = String(date.getHours()).padStart(2, '0');
      let minutes = String(date.getMinutes()).padStart(2, '0');
      let milliseconds = String(Math.round(date.getMilliseconds() / 10)).padStart(2, '0');

      let uuid = day + hours + minutes + milliseconds;
      localStorage.setItem("uuid",uuid)
      const shuffle = require('lodash.shuffle');

      let stories = ['22', '17', '34'];
      stories = shuffle(stories);

      const forms = {
        YesGeneratedYesFree: 'https://docs.google.com/forms/d/e/1FAIpQLSfLfBTW5j2V6i9GQkU8Ec7Bvxvg874wzX2-QE_DT7hjYA7xfQ/viewform?usp=pp_url&entry.1595853306=',
        NoGeneratedYesFree: 'https://docs.google.com/forms/d/e/1FAIpQLScQ5QhVx89zyyg7x2_bF1e_EVerWSSPqHr1cwXTXevoQZ8Xkw/viewform?usp=pp_url&entry.1595853306=',
        NoGeneratedNoFree: 'https://docs.google.com/forms/d/e/1FAIpQLSfeZvfFFXEkZcgJUII9QU0tI2gFqKZpzPS0C8Z3j3Efp3p7yA/viewform?usp=pp_url&entry.1595853306='
      };
      let systems = Object.keys(forms);
      systems = shuffle(systems);
      localStorage.setItem("systems", JSON.stringify(systems))
      localStorage.setItem("stories", JSON.stringify(stories))
      localStorage.setItem("forms", JSON.stringify(forms))
      this.addToPhase()
      location.href='https://docs.google.com/forms/d/e/1FAIpQLSffiDFWoWlHPwoG_6C-BTEe-4eb7NYEKgAdoWsE9bsZcp4vDg/viewform?usp=pp_url&entry.219259167='+this.getUuid()
    }
    else if(this.getPhase() === 4){
      this.addToPhase()
      location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSerXyZfiMqQMFzHrtscXJK8PIu3PUbeMPQszR7I1GMOegTmxw/viewform?usp=pp_url&entry.641522438='+this.getUuid()
    }
    else if(this.getPhase() === 5){
      localStorage.removeItem("uuid")
      localStorage.removeItem("phase")
    }
  }
}
</script>
<style scoped>
#cont{
  margin-top:100px;
  margin-bottom:30px
}
</style>